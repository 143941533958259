@charset 'UTF-8';
/* @FONT-FACE loads font into browser */
@font-face {
  font-family: 'Eras';
  src: url('../../fonts/eras/ERASLGHT.woff') format('woff'),
       url('../../fonts/eras/Eraslght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'typicons';
  src: url('../../fonts/typicons/typicons.eot');
  src: url('../../fonts/typicons/typicons.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/typicons/typicons.woff') format('woff'),
       url('../../fonts/typicons/typicons.ttf') format('truetype'),
       url('../../fonts/typicons/typicons.svg#typicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import url(http://fonts.googleapis.com/css?family=Poiret+One|Grand+Hotel|Indie+Flower|Homenaje|Open+Sans:400italic,400,700);
@import (reference) "vars.less";
@import (reference) "mixins.less";

/* ----------------------------------------------------> */
/* design definitions                                  > */
/* ----------------------------------------------------> */

body {
    ::-webkit-scrollbar { width: 12px; }
    ::-webkit-scrollbar-track {background-color: white; -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.10); border-radius: 10px;  }
    ::-webkit-scrollbar-thumb { background-color: white;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.25); background-color: rgba(0,0,0,0.05); border-radius: 10px; }

    // general definitions
    margin: 0;
    padding: 0;
    background-color: @dark-grey;
    font-family: @font-basic;
    font-size: @font-normal;
    font-weight: normal;
    font-style: normal;
    color: @mid-grey;
    
    a { text-decoration: none; cursor: pointer; }
    img { vertical-align: middle; 
        &.icon { 
            vertical-align: text-bottom; 
            &-bullet { width: 10px; height: 10px; }
        }
    }
        
    #header{ 
        .wrapper { .display-flex; }
        position: fixed;
        top: 0;
        z-index: 900; /* always on top */
        width: 100%;
        height: @header-height;
        overflow: visible !important;
        background-color: white;
        box-shadow: 0 0 10px rgba(0,0,0,.15); 
        .left {
            .flex(1);
            white-space: nowrap;
            #logo {
                white-space: nowrap;
                margin: 0 10px 0 0;
                float: left;
                & > div {
                    //.transition("hight,width,border",2s);
                    display: inline-block;
                    width: @logo-img-size; 
                    height: @logo-img-size;
                    .border-radius(50%); 
                    border:  5px solid white;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                & > div:first-child {
                    background-image: url(@logo-img-green); }
                & > div:nth-child(2) {
                    background-image: url(@logo-img-red); }
                & > div:last-child {
                    background-image: url(@logo-img-yellow); }
            }                
            #page-title {
                font-size: 2.8em;
                font-family: @font-headlines;
                //.transition(font-size,1s);
                span:first-child {
                    font-weight:bold;
                }
                a { color: @mid-grey; }
                #page-subtitle {
                    display:block;
                    margin: -8px auto auto 120px;
                    font-size: small;
                    font-family: @font-written;
                    font-style: italic;
                    letter-spacing: 1px;
                    line-height: 100%;
                    color: @mid-grey;
                    z-index: -2;
                }
            }
        }
        .right {
            .flex(2);
            #userinfo {
                position: relative;
                width: 100%;
                height: @header-user-height;
                line-height: @header-user-height;
                overflow: visible !important;
                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    float:right;
                    li { 
                        display: inline-block; 
                        float: left;
                    }
                }
                & .field {
                    cursor: pointer;
                    background-color: white;
                    display: inline-block;
                    margin: 0;
                    padding: 0 10px;
                    ul {
                        display: block;
                        width: 150px;
                        padding: 0; 
                        margin: 0 0 0 -11px;
                        li {
                            display: block; 
                            float: none;
                            border-bottom: 1px solid @light-grey;
                            border-left: 1px solid @light-grey;
                            border-right: 1px solid @light-grey;
                        }
                    }
                    &-home { 
                        border-left: 1px solid @light-grey;
                        display: none;
                        &-img {
                            display: inline-block;
                            width: 25px;
                            height: 25px;
                            vertical-align: middle;
                            background-image: url('@{img-path}favicon.png');
                            background-repeat: no-repeat;
                            background-position: center top;
                            background-size: 20px;
                        }
                    }
                    &-user {
                        border-right: 1px solid @light-grey;
                        border-left: 1px solid @light-grey;
                        text-transform: uppercase;
                        font-weight: normal;
                        font-family: @font-header-menu;
                        font-size: 1.1em;
                        letter-spacing: 1px;
                    }
                    &-notification {
                        .notification-image { 
                            display: inline-block;
                            background-image: url('@{img-path}icons/comment_box.png');
                            background-repeat: no-repeat;
                            background-position: center center;
                            width: 25px; height: auto;
                            text-align: right;
                            .badge {
                                display: inline-block;
                                font-size: xx-small;
                                color: white;
                                line-height: 1.5em;
                                .border-radius(0.5em);
                                padding: 0 0.3em;
                                background-color: @logo-red;
                                top: 5px;
                                right: 0;
                                position: relative;
                                }
                        }
                    }
                    &-menu { 
                        background-color: white;
                        border-right: 1px solid @light-grey;
                        border-left: 1px solid @light-grey;
                        width: @header-user-height -10px;
                        text-align: center;
                        ul { 
                            float: right;
                            width: 151px*3;
                            margin: 0 ;
                            margin-left: -(151px*3)-14px + @header-user-height ;
                            padding: 0;
                            border-style: solid;
                            border-width: 1px 0 0  1px ;
                            border-color: @light-grey;
                            li, li.field-sub { 
                                float: left;
                                width: 151px-21px;
                                border-style: solid;
                                border-width: 0 1px 1px 0;
                                border-color: @light-grey;
                                a { }
                            }
                        }
                    }
                    &-sub {
                        line-height: 2.5em;
                        font-family: @font-header-menu;
                        font-size: @font-normal;
                        text-align: left;
                        text-transform: uppercase;
                        letter-spacing: 0;
                        &:first-child { border-top: 1px solid @light-grey; } 
                        & img { margin-right: 5px; }
                    }
                    &:hover {
                        .bg-fade(lighten(@light-grey,5%),white);
                        .button-menu-toggle .bar { background-color: @dark-grey; }
                    }
                    a { 
                        display: block; 
                        margin: 0 -10px;
                        padding: 0 10px;
                        color: @mid-grey;
                        &:hover {
                            color: @dark-grey;
                            text-shadow: 1px 1px @light-grey;
                        }
                    }
                }      
                #user-menu {
                    width: 100%;
                    img { vertical-align: text-bottom; }
                    .info { vertical-align: middle; }
                    .avatar {
                        display: inline-block;
                        position: relative;
                        width: 25px; 
                        height: 25px; 
                        vertical-align: middle;
                        background-size: 100%;
                        .border-radius(5px);
                    }
                }           
                #logon-bar {
                    text-align: right;
                    span {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        vertical-align: middle;
                        &.login-user-img {
                            background-image: url('@{img-path}icons/User16.png');
                        }
                        &.login-pw-img {
                            background-image: url('@{img-path}icons/Lock16.png');
                        }
                    }
                }
                #notification-box {
                        width: 260px;
                        margin-left: -223px;
                        max-height: 280px;
                        overflow: auto;
                    .notification-element {
                        width: auto;
                        padding: 10px;
                        line-height: 1.5em;
                        .avatar { float: left; margin-right: 10px; }
                        .text {
                            font-family: @font-basic;
                            font-size: 0.85em;
                            text-transform: initial;
                        }
                        img.icon { float: right; margin: 20px -5px 0 0; }
                        img + img { margin: 2px -10px 0 0; }
                    }   
                }
                input[type=text], input[type=password] {
                    .border-radius(5px);
                    border: 0;
                    padding: 3px;
                    &[type=text], &[type=password] {
                        background-color: white;
                        width: 100px;
                        max-width: 25%;
                        border-top: 1px solid #ccc;
                        border-bottom: 1px solid #fff;
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                        box-shadow: -1px 1px 6px #f5f5f5, inset 0 1px 5px #eee;
                        .transition("width,background",1s);
                        &:hover, &:focus {
                            border-color: @logo-yellow;
                        }
                        &:focus {
                            width: 125px;
                            outline: none;
                        }
                    }
                    &[type=button], &[type=submit] {
                        .border-radius(50%);
                        height: 20px;
                        width: 20px;
                        background-color: @logo-yellow;
                    }
                }
            }  
        }
        
        #header-navi {
            min-height: @header-navi-height;
            color: @light-grey;
            min-width: 70px*4;
            width: 100%;
            margin: 0;
            > ul {
                list-style-type: none;
                line-height: @header-navi-height - 10px;
                padding: 0;
                margin: 3px 0;
                .border-radius(5px);
                border: 1px solid @dark-grey;
                background-color: lighten(@dark-grey,15%);
                box-shadow: -1px 1px 1px rgba(0,0,0,.2), inset -1px 1px 3px lighten(@dark-grey,5%);
                .display-flex;
                > li {
                    color: @light-grey;
                    font-family: @font-header-menu;
                    font-size: 1.3em;
                    text-align: center;
                    text-transform: uppercase;
                    border-right: 2px solid lighten(@dark-grey,10%);
                    border-left: 1px solid lighten(@dark-grey,20%);
                    .flex(1);
                    ul {
                        width: 150px;
                        font-size: 0.75em;
                        margin: 0;
                        padding: 3px 0 0 0;
                        li {
                            .border-radius(5px);
                            background-color: rgba(0,0,0,0.66);
                            display: block;
                            text-align: left;
                            margin: 1px 0;
                            padding: 0 5px;
                            a {
                                padding: 0 5px;
                                margin: 0 -5px;
                            }
                            span { float: right; }
                        }
                    }
                    &:first-child {
                        .border-radius(top-left,5px);
                        .border-radius(bottom-left,5px);
                        border-left: 0;
                    }
                    &:last-child {
                        .border-radius(top-right,5px);
                        .border-radius(bottom-right,5px);
                        border-right: 0;
                    }
                }
                a { color: @light-grey; }
            }
        }  
    } 
    
    main {
        display: block;
        background-color: @light-grey;
        background-image: url('@{img-path}v5/pattern_5.png');
        margin: @header-height 0 0 0;
        .wrapper {
            .display-flex;
            padding: 25px 0 25px 0;
            min-height: 500px;
            background-color: white;
            background-size: 200px 200px;
            background-repeat: repeat-x;
            .bg-fade(#f5f5f5,white);
            border-left: 5px solid white;
            border-right: 5px solid white;
            box-shadow: 0 0 15px 10px rgba(0,0,0,0.1);
            & > * {
                margin-left: 20px;
                margin-right: 20px;
            }
            .sidebar { 
                width: 25%; 
                min-width: 150px;
                max-width: 250px; 
                padding: 20px 0 0 0;
                &-left { margin-right: 0; }
                &-right { margin-left: 0; }
                &-block {
                    display: block;
                    width: auto;
                    margin: 0 0 15px 0;
                    padding: 5px 2px 1px 3px;
                    .border-radius(10px);
                    box-shadow: -1px 1px 2px rgba(0,0,0,0.2);
                    &-inner {
                        display:block;
                        padding: 3%;
                        .border-radius(8px);
                        background-color: white;
                    }
                    &-head {
                        font-family: @font-headlines;
                        font-size: 1.25em;
                        border-bottom: 1px solid @light-grey;
                        margin-bottom: 5px;
                    }
                    &-sub {
                        display: block;
                        text-align: right;
                        font-size: 0.6em;
                        margin: -10px 0 0 0;
                        font-family: @font-basic;
                        color: rgba(0,0,0,0.4);
                    }
                }
                & form.input { 
                    .display-flex;
                    & > div:first-child { .flex(2); }
                    & > div:last-child { .flex(1); max-width: 100px; }
                    & input[type=text] { display: block; width: 100%; .border-box; }
                    & input[type=submit] { display: block; margin: 0;width: 100%; .border-box;  }
                } 
                &-search { background-color: @light-grey; }
                &-blubber { 
                    background-color: @logo-yellow; 
                    form { margin-bottom: 5px; }
                    &-elements {
                        max-height: 150px;
                        overflow: auto;
                    }
                    &-element {
                        display: block;
                        width: auto;
                        padding: 1%;
                        font-size: @font-small;
                        &-head { color: rgba(0,0,0,0.4) }
                        &:nth-child(even) {
                            background-color: @light-grey;
                        }
                    }
                }
                &-online { background-color: @logo-green; }
                &-feature { background-color: @logo-red; }
            }
            .content { .flex(1); }
        }
        
        h1, h2, h3, h4 {
            font-family: @font-headlines;
            font-weight: normal;
            font-size: 1em;
            color: @mid-grey;
            margin:  1em 0;
        }
        h1 {
            font-size: 1.6em;
            border-bottom: 1px solid @logo-green;
            text-shadow: -1px 1px 3px rgba(128,128,128,0.3);
        }
        h2 {
            font-size: 1.3em;
            border-bottom: 1px solid @logo-yellow;
            text-shadow: 1px 1px 2px rgba(999,999,999,0.5);
            padding: 1px 0 0 1px;
            letter-spacing: 1px;
        }
        h1 + h2 {
            margin-top: -10px; 
        }
        h3 {
            font-size: 1.25em; 
        }
        
        a { 
            color: @logo-red; 
            &:hover {
                color: @logo-green;
                border-bottom: 1px solid @logo-yellow;
            } 
            &.no-border:hover {
                border: 0;
            }
        }
    }
    
    footer {
        background-color: @dark-grey;
        a {
            color: @light-grey;
        }
        a.topic_link {
            .transition("color",1s);
            display:block;
            color: @mid-grey;
            margin: 0.25em 0;
            padding: 1%;
            &:nth-child(even) {
                background-color: darken(@dark-grey,3%);
            }
            &:nth-child(odd) {
                background-color: lighten(@dark-grey,3%);
            }
            & span.date { display: block; }
            &:hover { color: @light-grey; }
        }
        a.affi-link {
            .transition("background-color",1s);
            display: inline-block;
            padding: 5px;
            margin: 1%;
            background-color: lighten(@dark-grey,3%);
            border: 1px solid lighten(@dark-grey,3%);
            .border-radius(5px);
            img { opacity: 0.5; 
            .transition("opacity",1s); }
            &:hover {
                background-color: darken(@dark-grey,3%);
                img { opacity: 1; }
            }
        }
        a.facebooklink, a.googlelink, a.twitterlink {
            display: inline-block;
            position: relative;
            margin: 5px;
            width: 32px;
            height: 32px;
            font-size: 32px;
            font-weight: bold;
            background-color: @mid-grey;
            border: 5px solid;
            color: @dark-grey;
            .border-radius(50%); 
            .transition("background-color,color,border-color,",1s);
            &:hover {   
                color: @light-grey;
                border-color: @light-grey; 
            }
        }
        a.facebooklink {
            &:before{
                content: 'f';
                position: absolute;
                bottom: -10px;
                right: 5px;
            }
            &:hover { 
                background-color: #3B5998; }
        }
        a.googlelink {
            &:before {
                content: 'g';
                position: absolute;
                bottom: -5px;
                right: 5px;
            }
            &:hover { background-color: #dd4b39; }
        }
        a.twitterlink {
            &:before {
                content: 't';
                position: absolute;
                bottom: -8px;
                right: 4px;
            }
            &:hover { background-color: #1da1f2; }
        }
        .box {
            padding: 1%;
        }
        .headline {
            font-family: @font-headlines;
            font-weight: normal;
            font-size: 1.25em;
            border-bottom: 1px solid;
            margin: 0.5em 0;
        }
        .bottomline{
            background-color: lighten(@dark-grey,2%);
            margin: 2em 0;
            padding: 0.5em ;
        }
    }
    
    .wrapper {
        width: @wrapper-width;
        max-width: @max-wrapper-width;
        margin: 0 auto;
    }

}

/* special & sub page design elements -----------------> */
@import "generals.less";
// Forms & Input styles
@import "input.less";
// BBC Editor styles
@import "bbc.less";
// Memberprofil styles
@import "cards.less";
// Memberprofil styles
//@import "memberprofil.less";


/* responsive -----------------------------------------> */
/* tablets horizontal and small screens*/
@media 
only screen and (max-width : 1023px){ 
    body .wrapper { width: 90%; }
    body #header .left {
        width: 40%;
        .flex(0);
    }
    body #header .left #logo div { 
        width: (@logo-img-size/3)*2; 
        height:  (@logo-img-size/3)*2; 
        background-size: 100%;
        border: 2px solid white;
        margin: 10px 0 0 0;
    }
    body #header .left #page-title {
        font-size: 2em;
        margin: 10px 0 0 0;
    }
    body #header .left #page-title #page-subtitle {
        display: none; 
    }
}

/* smartphone horizontal / tablets vertical */
@media 
only screen and (max-width : 640px) {
    body .wrapper { width: 100%;   }
    body #header .wrapper { width: 95%; }
    body #header #userinfo #user-menu div.avatar { display: none; }
    body #header #logo { display: none; }
    body main .wrapper { 
        border:0px; 
        display: block;    
    }
    body main .wrapper .sidebar {
        border-top: 1px solid @light-grey;
        margin-top: 2em;
        width:100%;
        min-width:100%;
        max-width:100%;
        justify-content: space-around;
        .display-flex;
        flex-wrap: wrap;
        .sidebar-block { width: 90%; }
    }
}
	
/* smartphone vertical */
@media 
only screen and (max-width : 480px){ 
    img { max-width: 100%; }
    .to-hide {  display:none !important; }
    .to-show {  display:inherit !important; }
    div.to-show {  display: block !important; }
    body .wrapper, body #header .wrapper { width: auto;  }
    body #header { height: auto; }
    body #header .wrapper { 
        .left {  display: none; }
        .right {  display: block; }
        #userinfo {
            ul { float: none; }
            .field-menu { float: right; 
                ul {
                    margin: 0;
                    position: absolute;
                    top: @header-height;
                    left:0;
                    width: 100%;
                    max-width: 100%;
                    li.field-sub { .border-box; max-width: 50%; width: 50%; min-width: 150px;}
                }
            }
            .field-home { display: none; float:left;}
            #notification-box {
                position: absolute;
                top: @header-height;
                left: 0;
                margin: 0;
            }
        }
    }
    body #header #header-navi { 
        min-height: initial;
        ul { padding: 0; margin: 0;
            .border-radius(0);
            border: 0;
            box-shadow: none;
            li { 
                border-width: 0 1px 0 0;
                border-style: solid;
                border-color: @mid-grey;
                &:last { border: 0; }
            }
        }
    }
    body main { 
        .wrapper { 
            .wrapper > * { margin-left: 10px; margin-right: 10px; }
        }
    }
}