/*
    BBC Editor Styling
*/
@import (reference) "vars.less";
@import (reference) "style.less";

.bbc_editor {
    display: inline-block;
    .toolbar {
        background-color: @light-grey;
        border: 1px solid @light-grey;
        .border-radius(5px);
        .font_colors {
            margin: 2px;
            display: block;
            span.color {
                display: inline-block;
                width: 15px;
                height: 15px;
                margin: 2px;
            }
        }
        .emoticons {
            padding: 2px;
            background-color: white;
        }
    }
}