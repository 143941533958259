/*
    INPUT & FORMS
*/
@import (reference) "vars.less";
@import (reference) "mixins.less";
@import (reference) "generals.less";

input[type=text],
input[type=number],
input[type=password],
select,
textarea {
    font-size: 12px;
    color: @dark-grey;
    .border-radius(5px);
    border: 1px solid lighten(@mid-grey,15%);
    padding: 3px;
    background-color: white;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    box-shadow: -1px 1px 6px #f5f5f5,inset 0 1px 5px #eee;
    &:focus {
        outline: 0;
        border-color: #ffd545;
    }
}
input[type=submit], 
input[type=button] {
    .button;
    border: 0;
    font-size: 12px;
}
input[type=text],
input[type=password]{
    padding: 0.5em 0.7em;
    max-widht: 100%;
}
input[type=image]{
    background-color: white;
    }
.input-false {
    border-color: red !important;
}