/*
    GENERAL DESIGN ELEMENTS
*/
@import (reference) "vars.less";
@import (reference) "mixins.less";

// table styling
table {
    &.full-width { width: 100%; }
    &.list {
        border-width: 0;
        border-spacing: 0;
        border-collapse: collapse;
        tr {
            td { padding: 0.4em 0.6em; }
            th {
                padding: 0.3em 0.6em;
                font-family: @font-headlines;
                font-size: 1.15em;
                font-weight: normal;
                font-style: normal;
                text-align: left;
                backgroud-color: transparent;
                border-bottom: 1px solid @light-grey;
            }
            &:nth-child(even) td { background-color: white; }
            &:nth-child(odd) td { background-color: @light-grey; }
            &.new td {
                border-bottom: 1px solid @logo-yellow; 
                border-top: 1px solid @logo-yellow; 
                &:first-child { border-left: 8px solid @logo-yellow; }
            }
            &.weaker td { opacity: 0.5; }
        }
    }
    table tr td { background-color: transparent !important; }
}
// List stylings
ul {
    &.no-bullets {
        list-style-type: none;
    }
    &.horizontal {
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
            margin: 0 2px;
            padding: 0 0.2em;
        }
    }
    &.list {
        margin: 0;
        padding: 0;
        li {   
            &:nth-child(even) { background-color: white; }
            &:nth-child(odd) { background-color: @light-grey; }
        }
    }
    &.card-number {
        li { 
            text-align: center;
            font-size: @font-small;
            a { color: @mid-grey; border:none; }
        }
        li.card-number-default {
            border: 1px solid lighten(@mid-grey,25%);
            background-color: white;
            width: 20px;
            padding: 0;
        }
        li.card-number-fade {
            border: 1px solid lighten(@mid-grey,25%);
            background-color: @light-grey;
            opacity: 0.33;
            width: 20px;
            padding: 0;
        }
        li.card-number-highlight {
            border: 1px solid @logo-green;
            background-color: @logo-green;
            color: white;
            width: 20px;
            padding: 0;
        }
        li.card-number-highlight-red {
            border: 1px solid @logo-red;
            background-color: @logo-red;
            color: white;
            width: 20px;
            padding: 0;
        }
    }
}
// input - suggestions list
ul.ui-autocomplete {
    display: inline-block;
    min-width: 100px;
    max-width: 150px;
    background-color: white;
    border: 1px solid @light-grey;
    list-style-type: none;
    padding: 0;
    margin: 0;
    li.ui-menu-item {
        margin: 0;
        padding: 0.25em 0.75em;
    }
}

// fieldsets
fieldset { .fieldset }

//some general classes to hand down or something
.bold { font-weight: bold !important; }
.italic { font-style: italic !important; }
.uppercase { text-transform: uppercase !important; }
.setshort { 
    text-transform: uppercase !important; 
    &:before {
        content: '[';
        color: rgba(0,0,0,0.33);
    }
    &:after {
        content: ']';
        color: rgba(0,0,0,0.33);
    }
}
.vertical-inline { transform: rotate(270deg); display: inline-block; }
.font {
    &-size {
        &-extrasmall { font-size: xx-small; }
        &-small { font-size: @font-small; }
        &-big { font-size: 1.2em; }
        &-extralarge { font-size: @font-extralarge; }
        &-normal { font-size: 1em; }
    }
    &-color {
        &-new { color: silver; }
        &-trade { color: limegreen; }
        &-might { color: royalblue; }
        &-reserved { color: orange; }
        &-collect { color: red; }
        &-keep { color: darkviolet; }
        &-wish { color: #ffd81a; }
    }
}
.align {
    &-left { text-align: left; }
    &-right { text-align: right; }
    &-center { text-align: center !important; } 
}
.clear-all, .clearer { clear:both; }
.flex-box { 
    .display-flex(); 
    .box { .flex(1); }
}
.flex-box-to-block480 { 
    .flex-box;
    @media only screen and (max-width: 480px) {
        display: block;
    }
}
.flex-box-to-block640  { 
    .flex-box;
    @media only screen and (max-width: 640px) {
        display: block;
    }
}

.votestatus { 
    display: inline-block; 
    max-width: 100px; 
    min-width:50px; 
    width: 100%; 
    height: 10px; 
    background-color: lightgrey; 
    & .green { 
        background-color: @logo-green; 
        display:block; 
        height:100%;
    }
    & .red { 
        background-color: @logo-red; 
        display:block; 
        height:100%;
    }
}

.progressbar {
    display: block;
    .bar { 
        display: inline-block;
        min-width: 100px;
        height: auto;
        padding: 1px; 
        box-shadow: inset 1px -1px 5px -1px rgba(0,0,0,0.05),
                          0 0 0px 1px rgba(255,255,255,0.5);
        background-color: rgba(0,0,0,0.025);
        border: 1px solid @light-grey;
        .border-radius(10px);
        text-align: left;
        vertical-align: middle;
        .filled {
            .border-radius(10px);
            .border-box();
            box-shadow: inset 1px -1px 1px 0px rgba(255,255,255,0.5);
            background-color: @logo-green;
            height:5px;
            line-height: 5px;
            border:1px solid @logo-green;
        }
    }
    &-slim {
        height: 3px;
        line-height: 3px;
        .bar { 
            padding:0;
            border:0;
            .border-radius(0px);
            .filled {
                .border-radius(0px);
                border:0;
                box-shadow: none;
                height: 3px;
            }
        }
    }
    &-maxi {
        .bar {
            .filled {
                box-shadow: inset 1px -1px 5px 1px rgba(255,255,255,0.5);
                height: 10px;
            }
        }
    }
}
.pollscript {
    display:block;
    margin: auto;
    padding: 1em;
    width: 250px;
    max-width: 100%;
    border: 1px solid @light-grey;
    background-color: white;
    .poll-element {
        &-question {.bold}
        &-answer td,
        &-answer {
            padding: 0.5em;
            &-percent {.bold}
            &:nth-child(even) {background-color: @light-grey}
            &:nth-child(odd) {}
        }
        &-sum-votes {}
    }
}

.quotation-block { .italic;
    &:before { content: open-quote; color: rgba(0,0,0,0.25); .bold; display: inline-block; margin: 0 2px 0 0; }
    &:after { content: close-quote; color: rgba(0,0,0,0.25); .bold; display: inline-block; margin: 0 0 0 2px; }
}

.quote {
    border-left: 2px solid @logo-red;
    padding: 0.1em 0.1em 0.1em 0.5em;
    & > .quote {
        border-left: 2px solid @logo-green;
        & > .quote {
            border-left: 2px solid @logo-yellow;
            & > .quote {
                border-left: 2px solid @light-grey;
            }
        }
    }
}

.border-box {      
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}
.fieldset {
    background-color: transparent;
    .border-radius(10px);
    border: 1px solid  @light-grey;
    margin: 15px 0;
    &-dark {
        background-color: @light-grey;
    }
    &-content {
        .flex-box;
        @media only screen and (max-width: 640px) {
            display: block;
        }
    }
    legend {
        background-color: white;
        .border-radius(10px);
        box-shadow: 0 1px 1px rgba(0,0,0,0.25);
        font-size: @font-small;
        padding: 0.3em 0.6em;
        text-transform: uppercase
    }
}
// to hide or display content if needed
.to-show { display: none !important; }
.to-hide {
    &-1024 {
        @media 
        only screen and (max-device-width : 1023px),
        only screen and (max-width : 1023px){ 
            display: none !important;
        }
    }
    &-640 {
        @media 
        only screen and (max-device-width : 640px),
        only screen and (max-width : 640px) {
            display: none !important;
        }
    }
    &-480 {
        @media 
        only screen and (max-device-width : 480px),
        only screen and (max-width : 480px) {
            display: none !important;
        }
    }
}

// reuseable div elements
.user-avatar {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    margin: 2px 0;
    width: 100px; height: 100px; .border-radius(10px);
    &-16 { width: 16px; height: 16px; .border-radius(3px); }
    &-25 { width: 25px; height: 25px; .border-radius(5px); }
    &-32 { width: 32px; height: 32px; .border-radius(6px); }
    &-50 { width: 50px; height: 50px; .border-radius(8px); }
}
.footer-bar {
    padding: 2px 0;
    margin: 5px 0;
    box-shadow: inset 0 15px 15px -15px @light-grey;
}
.headline-option {
    float:right;
    font-size: @font-normal;
    font-family: @font-basic;
    &-button {
        display: inline;
        font-size: @font-small;
        margin: 0 0.5em;
        border-bottom: 2px solid transparent;
        cursor: default;
    }
    &-clickable {
        &:hover {
            cursor: pointer;
            border-color: @logo-red;
        }
    }
        
}
.button-menu-toggle {
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    .bar {
        display: block;
        height: 2px;
        margin: 4px;
        background-color: grey;
        .border-radius(2px);
    }
}
.toggle-icon:before {
        font-family: "tbicons";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        speak: none;
        content: '\e826'; 
        display: table-cell;
        float: right;
        width: 16px;
        height: 16px; 
        text-align:center;
        cursor: pointer;
}

// Sub-/Dropdown Menus
.has-sub-menu {
    ul, .sub-menu {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        .transition("visibility,opacity",0.4s);
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    &:hover ul, &:hover .sub-menu {
        visibility: visible;
        opacity: 1;
        z-index: 1;
    }
}

// Tooltips
.has-tooltip {
    position: relative; 
    display: inline-block;
    > .tooltip { 
		display: none;
		white-space: nowrap;
		cursor: default;
		
		background-color: rgba(000,000,000,0.66);
		border-radius: 10px;
		padding: 10px;
		
		color: @light-grey;
		font-family: @font-basic;
		font-size: 8pt;
		font-weight: normal;
		text-align: left;
		text-transform: none;
		text-shadow: 0px 1px @dark-grey;
		line-height: normal;
    }
	&:hover > .tooltip {
        position: absolute;
	    display: block;
        margin-top: 10px; 
        left:50%;
        transform:translateX(-50%);
        z-index: 99;
        &:before {
            content: "";
            position: absolute;
            top: -20px;
            left:50%;
            transform:translateX(-50%);
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent rgba(000,000,000,0.66) transparent;
        }
	}
}

// Buttons
.button {
    > a { 
        display: block; 
        color: inherit !important; 
        text-decoration: none !important;
        border: 0 !important;
    }
 	display: inline-block;
 	position: relative;
	padding: 0.5em 0.7em;
	margin: 0.5em;
	.border-radius(0.3em);
	box-shadow: 0 1px 5px rgba(0,0,0,0.25);
	font-family: Arial;
	font-size: 1em;
	font-weight: normal;
	cursor: default;
    // Basic Color Definitions
    color: @mid-grey;
    text-shadow: 0 1px 0 white;
    border: 0;
	border-bottom: 1px solid white;
	background-color: @light-grey;
	.bg-fade(white,@light-grey);
	&:hover { 
        cursor: pointer; 
        color: darken(@mid-grey,10%);
        .bg-fade(white,lighten(@light-grey,2%));
    }
	&-dark {
	    // Dark Color Definitions
	    color: white !important;
	    text-shadow: 0 -1px 1px black !important;
		border-bottom: 1px solid @mid-grey !important;
		background-color: @dark-grey !important;
		.bg-fade(@mid-grey,@dark-grey) !important;
		&:hover {
		    .bg-fade(lighten(@mid-grey,10%),@dark-grey) !important;
	    }
	}
	&-yellow {
	    // Yellow Color Definitions
	    color: white !important;
	    text-shadow: 0 -1px 0px darken(@logo-yellow,20%) !important;
		border-bottom: 1px solid @logo-yellow !important;
		background-color: @logo-yellow !important;
		.bg-fade(@logo-yellow,darken(@logo-yellow,17%)) !important;
		&:hover {
		    .bg-fade(lighten(@logo-yellow,10%),darken(@logo-yellow,17%)) !important;
        }
	}
	&-green {
	    // Yellow Color Definitions
	    color: white !important;
	    text-shadow: 0 -1px 0px darken(@logo-green,20%) !important;
		border-bottom: 1px solid @logo-green !important;
		background-color: @logo-green !important;
		.bg-fade(@logo-green,darken(@logo-green,17%)) !important;
		&:hover {
		    .bg-fade(lighten(@logo-green,10%),darken(@logo-green,17%)) !important;
        }
	}
	&-red {
	    // Yellow Color Definitions
	    color: white !important;
	    text-shadow: 0 -1px 0px darken(@logo-red,20%) !important;
		border-bottom: 1px solid @logo-red !important;
		background-color: @logo-red !important;
		.bg-fade(@logo-red,darken(@logo-red,17%)) !important;
		&:hover {
		    .bg-fade(lighten(@logo-red,10%),darken(@logo-red,17%)) !important;
        }
	}
	&-deep {
		&:before {
		 	content: "";
		 	display: inline-block;
		 	position: absolute;
		 	top: -5px;
		 	right: -5px;
		 	bottom: -6px;
		 	left: -5px;
		 	background-color: rgba(0,0,0,0.025);
			border-radius: 0.5em;
			border-top: 1px solid rgba(0,0,0,0.1);
			box-shadow: inset 0 2px 10px rgba(0,0,0,0.05),0 0 20px rgba(0,0,0,0.05);
		}
	}
	&-mini {
	    padding: 0.2em 0.4em;
	    font-size: 0.9em;
    }
	&-small {
	    padding: 0.3em 0.5em;
    }
    &-large {
        font-size: @font-large;
	    padding: 0.3em 0.5em;
    }
}
.button-group {
	display: inline-flex;
	.button {
		border-radius: 0;
		margin-left: 0;
		margin-right: 0;
	}
	.button:first-child {
		border-top-left-radius: 0.5em;
		border-bottom-left-radius: 0.5em;
		margin-right: 0;
	}
	.button:last-child {
		border-top-right-radius: 0.5em;
		border-bottom-right-radius: 0.5em;
		margin-left: 0;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
}

// Tab-Line/-HORIZONTAL
.tab-line {
    display: block;
    margin: 0 0 5px 0 !important;
    padding: 0 10px !important;
    border-bottom: 1px solid @light-grey;
    box-shadow: 0 15px 10px -5px white;
    &-grey {
        box-shadow: inset 0 -10px 10px -5px lighten(@light-grey,4%), 0 15px 10px -5px white;
    }    
    .tab { 
        .transition(font-weight,0.5);
        display: inline-block;
        margin: 0 5px;
        padding: 0.4em 1.0em;
        cursor: pointer;
        .border-radius(top-left,5px);
        .border-radius(top-right,5px);
        border-width: 1px 1px 0 1px;
        border-color: lighten(@light-grey,4%);
        border-style: solid;
        background-color: white;
        .badge {
            .pill > .badge;
            font-size: @font-normal;
        }
        &:hover {
            color: @dark-grey;
            text-shadow: -1px 1px 1px @light-grey;
            border-color: @light-grey;
        }
        &-active {
            position: relative;
            bottom: -1px;
            cursor: default;
            color: @dark-grey;
            text-shadow: -1px 1px 1px @light-grey;
            border-color: @light-grey;
        }
        &-yellow {
            border-color: @logo-yellow;
        }
        &-green {
            border-color: @logo-green;
        }
        &-red {
            border-color: @logo-red;
        }
        ul, .sub-menu {
            text-shadow: none;
            color: @mid-grey;
            li {display: block;
                position: relative;
                background-color: white;
                padding: 0.4em 1.5em;
                border-style: solid;
                border-color: @light-grey;
                border-width: 1px 1px 0 1px;
                left: -1em;
                top: 0.4em;
                &:last-child { 
                    .border-radius(bottom-left, 5px);
                    .border-radius(bottom-right, 5px);
                    border-bottom: 1px solid @light-grey;
                }
            }
        }
    }
}
.tab-line-right {
    list-style-type: none;
    display: block;
    padding: 0;
    margin: 0;
    padding: 5px 0;
    box-shadow: inset 10px 0 10px -10px @light-grey;
    .tab {
        display: block;
        position: relative;
        padding: 0.3em 0.4em;
        margin: 0.2em 0;
        background-color: transparent;
        border-width: 1px 1px 1px 0;
        border-color: transparent; 
        border-style: solid;
        &:hover {
            color: @logo-red;
            cursor: pointer;
        }
        &-active, &-active:hover {
            background-color: white;
            border-width: 1px 1px 1px 0;
            border-color: @light-grey; 
            border-style: solid;
            .border-radius(top-right, 5px);
            .border-radius(bottom-right, 5px);
            color: @logo-green;
            cursor: default;
        }
        .badge {
            position: absolute;
            right: 5px;
            display: inline-block;
            background-color: @light-grey;
            padding: 0 0.2em;
            text-align: center;
            min-width: 1em;
            .border-radius(1em);
            color: @mid-grey;
            &-red {
                background-color: @logo-red;
                color: white;
            }
            &-yellow {
                background-color: @logo-yellow;
            }
            &-green {
                background-color: @logo-green;
                color: white;
            }
        }
    }
}
        
// Pills
.pills { text-align: center; margin: 15px 0;}
.pill {
    display: inline-block;
    border: 1px solid @light-grey;
    .border-radius(5px);
    background-color: white;
    padding: 0.4em 0.8em !important;
    margin: 2px !important;
    .badge {
        display: inline-block;
        background-color: @light-grey;
        font-size: @font-small;
        padding: 0.1em 0.2em;
        .border-radius(3px);
        margin: 0 0 0 0.4em; 
        &-red {
            background-color: @logo-red !important;
        }
    }
    &-clickable {
        cursor: pointer;
    }
    &-active {
        border-color: @logo-green;
        background-color: @logo-green;
        color: white;
        cursor: default;
        font-weight: bold;
        & .badge { 
            background-color: white;
            color: @mid-grey;
        }
        &:hover {
            border-color: @logo-green !important;
        }
    }
    &:hover {
        box-shadow: 0px 1px 2px rgba(0,0,0,0.05);
        border-color: @logo-yellow;
    }
}

// Pagination
.pagination {
    list-style-type: none;
    padding: 0;
    margin: 0;
    .pagination-element, 
    &-forward, 
    &-backwards {
        text-align: center;
        display: inline-block;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
        border: 1px solid @light-grey;
        .border-radius(1em);
        padding: 0.1em 0.4em;
        background-color: white;
        min-width: 0.75em;
        &:hover {
            color: @logo-red;
            border-color: @logo-yellow;
            box-shadow: inset 0 -2px 5px @light-grey;
        }
        &-active {
            border-color: @logo-green;
            box-shadow: inset 0 -2px 5px @light-grey;
        }
    }
    &-forward {
        &:before { content: "\203A"; }
        width: 1em;
    }
    &-backwards {
        &:before { content: "\2039"; }
        width: 1em;
    }
}

.filter-alpha {
    &-element {
        .pagination > .pagination-element;
        max-width: 0.75em;
    }
}

// "Tags"
.tag {
    display: inline-block;
    margin: 2px;
    padding: 0.2em 0.4em;
    .border-radius(6px);
    border: 1px solid @light-grey;
    background-color: white;
}
.tag-dark {
    .tag;
    background-color: @light-grey;
}

.label {
    .tag;
    &-red {
        background-color: @logo-red;
        border-color: @logo-red;
        color: white;
    }
    &-yellow {
        background-color: @logo-yellow;
        border-color: @logo-yellow;
        color: white;
    }
    &-green {
        background-color: @logo-green;
        border-color: @logo-green;
        color: white;
    }
}

// input cover ups
label.radio-cover {
    input {display:none;}
}

/* :before psuedo-selector inserts and styles icon */
.typcn {
    &:before {
    font-family: 'typicons';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    height: 1em;
    font-size: 1em;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    }
	&-tick:before {
        content: "\e11e";
	}
	&-cross:before {
        content: "\e122";
	}
	&-return:before {
        content: "\e010";
	}
	&-trade:before {
        content: "\e018";
	}
	&-msg:before {
        content: "\e0a5";
	}
	&-folder:before {
	    content: "\e07e";
	}
}