/*
    CARDS & MASTERCARDS (& FLAGS)
*/
@import (reference) "vars.less";
@import (reference) "mixins.less";
@import (reference) "generals.less";

.card {
    display: inline-block;
    padding: 0; 
    margin: 0;
    border: 1px solid transparent;
    background-color: transparent;
    &-img {
        display: block;
        position: relative;
        padding: 0; 
        margin: 0;
        width: 70px + 6px;
        height: 85px + 6px; 
        background-repeat: no-repeat;
        background-position: center center;
    }
    &-master-img {
        display: block;
        position: relative;
        padding: 0; 
        margin: 0;
        width: 110px + 10px;
        height: 85px; 
        background-repeat: no-repeat;
        background-position: 6px center;
    }
    &-master-img-empty {
        background-image: url('@{img-path}cards/album_not_masterd.gif');
    }
    &-flags {
        position: absolute; 
        top: 0; 
        right: 0;
    }
    &-counter {
        position: absolute; 
        bottom: 0; 
        right: 0;
        background-color: rgba(0,0,0,0.66);
        color: white;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        .border-radius(50%);
        font-size: 8px;
        font-weight: bold;
    }
    &-setlink {
        display: none;
        text-align: center;
    }
    &-checkbox {
        display: none;
    }
    &-marked .card-img {
        background-color: @logo-green;
        .border-radius(5px);
    }
}
.cardbox-card {
    margin: 1px;
    .card-setlink {
        display: block !important;
        margin-top: -6px;
    }
}
a .card {
    color: @mid-grey;
}

.flag {
    width: 16px;
    height: 16px;
    border: 1px solid;
    .border-radius(6px);
    background-color: white;
    display: inline-block;
    position: relative;
    text-align: center;
    &:before {
		display: inline-block;
		position: relative;
		font-family: "tbicons";
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		width: 12px;
		height: 12px;
		color: white;
		bottom: 2px; 
		text-align: center;
		font-family: "tbicons";
    }
    &-keep {
        border-color: DarkOrchid;
        background-color: DarkOrchid;
        &:before {
			content: '\e82f';
        }
    }
    &-collect {
        border-color: @logo-red;
        background-color: @logo-red;
        &:before {
			content: '\e82f';
        }
    }
    &-master {
        border-color: #ffd81a;
        background-color: white;
        &:before {
			content: '\e80b';
			color: #ffd81a;
			bottom: 0;
        }
    }
    &-wish {
        border-color: @logo-yellow;
        background-color: @logo-yellow;
        &:before {
			content: '\e803';
        }
    }
    &-duplicate {
        border-color: lightskyblue;
        background-color: lightskyblue;
        &:before {
        	content: '\e82a';
        }
    }
}

.bg-first-master {
    background-image: url('@{img-path}first_master.gif');
    background-repeat: no-repeat;
    background-position: top left;
}
.bg-second-master {
    background-image: url('@{img-path}second_master.gif');
    background-repeat: no-repeat;
    background-position: top left;
}
.bg-third-master {
    background-image: url('@{img-path}third_master.gif');
    background-repeat: no-repeat;
    background-position: top left;
}